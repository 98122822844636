@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  @apply overflow-hidden
}

button {
  @apply cursor-default md:cursor-pointer
}